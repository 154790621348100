let acc_type1 = sessionStorage.getItem("acc_type") || "user";
export const AUTH_ROUTES = {
  REGISTER: "/register",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  VERIFY_EMAIL: "/verify-email",
  PERSONAL_INFORMATION: "/user-details1",
  CREATE_ACCOUNT: "/user-details2",
  CREATE_INTEREST: "/user-details3",
  EMAIL_VERIFICATION: "/email/verification-notification",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD: "/reset-password",
  LOGOUT: "/logout",
  LOGIN_WITH_FACEBOOK: "/auth/facebook/callback",
  LOGIN_WITH_GOOGLE: "/auth/google/callback",

  // ACCOUNT AND PROFILE
  GET_USER: "/user/account",
  //UPDATE_PROFILE: (acct_type = acc_type1) => `/${acc_type1}/account?update-profile=true`,
  UPDATE_PROFILE: "/users",
  //https://f8.codester.dev/web/api/users

  UPDATE_PASSWORD: "/user/account?change-password=true",
  UPDATE_EMAIL: "/user/account?change-email=true",
  VERIFY_CHANGED_EMAIL: "/verify-email?changed-email=true",
  CHANGED_EMAIL_VERIFICATION:
    "/phone-no/verification-notification?changed-phone-no=true",

  GET_RANDOM_TRADERS_OR_USERS: () =>
    "/users/suggested",
  FOLLOW: (userId) => `/matchings/${userId}`,
  UNFOLLOW: (userId) => `/matchings/${userId}`,
  FOLLOWERS: (userId) => `/users/${userId}/followers`,
  GET_ALL_FOLLOWING_USERS: (userId) => `/users/${userId}/following`,
  //https://f8.codester.dev/web/api/users/1/followers
  
  //https://f8.codester.dev/web/api/matchings/1

  // MESSAGING
  GET_ALL_CHAT_CONTACTS: () => '/chats',
  SEND_MESSAGE: (acct_type = acc_type1) => `/${acc_type1}/chat`,

  // STORIES
  POST_STORIES: "/stories",
  GET_FOLLOWING_STORIES: "/stories/following",
  DISPLAY_STORY: (acct_type, storyId) => `/${acc_type1}/story/${storyId}`,
  GET_USER_STORIES: "/stories",
  GET_SPECIFIC_USER_STORIES: (acct_type = acc_type1, userId) =>
    `/${acc_type1}/story?user_stories&user_id=${userId}`,

  // TRADERS
  GET_ACCOUNT: "/users/authenticated",
  
  GET_ALL_TRADERS: "/user/trader",
  TRADERS_NOT_FOLLOWED: "/user/trader?not_followed=true",
  NOT_INTERESTED: '/uninterested',
  JOIN_GROUP: (invite_link) => `/groups/${invite_link}/join`,
  CHECKOUT: (groupId, planId) =>
    `/groups/${groupId}/plans/${planId}/payment`,
  PAYMENT_SUCCESSFUL: (intent_id) =>
  `/groups/payment/success/${intent_id}`, 
  GET_ALL_GROUPS: "/trading-groups",
  GET_GROUP_CATEGORIES: "/groups/categories",
  CREATE_NEW_GROUP: "/group-chat",
  UPDATE_GROUP: (account_type = acc_type1, groupId) =>
    `/${acc_type1}/group-chat/${groupId}?update=true`,
  GET_GROUP: (acct_type = acc_type1, id) => `/${acc_type1}/group-chat/${id}`,
  GET_USER_GROUPS: () => "/groups/joined",
  SEND_GROUP_MESSAGE: (acct_type = acc_type1) =>
    `/${acc_type1}/group-chat-message`,

  SEARCH_USER: (search, acc_type = acc_type1) => `/users?q=${search}`,
  GET_ANOTHER_USER_PROFILE_DETAILS: (id) =>
    `/users/${id}`,
  
  GET_PROFILE_DETAILS_WITHOUT_AUTH: (id) => `/all-user/${id}`,
  UPDATE_ABOUT: '/users/about',
  UPDATE_COVER_IMAGE: "/users/cover-picture",
  //UPDATE_SOCIAL_MEDIA: "/account?update_social_media=true",
  UPDATE_SOCIAL_MEDIA: "/users/social-media",
  //USER_VERIFICATION: "/account?user_verification=true",
  USER_VERIFICATION1: "/users/verifications1",
  USER_VERIFICATION2: "/users/verifications2",
  UPLOAD_POST: `/${acc_type1}/post`,
  UPDATE_POST: (id) => `/${acc_type1}/post/${id}`,
  GET_POSTS: `/${acc_type1}/post`,
  SEND_FIREBASE_ID: `/account?update_firebase_token=true`,
  GET_NOTIFICATIONS: '/users/notifications',
  GOOGLE_AUTH: "/google/oauth",
};
